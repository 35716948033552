import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Lock, LockOpen } from "@material-ui/icons";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Toast from "components/Toast/Toast.jsx";
import OrdersRequestController from "controllers/OrdersRequest";
import { ExpandMore, Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  containerView: {
    padding: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  lockIcon: {
    cursor: "pointer",
  },
  searchBox: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}));

const ManagementBlockedClients = (props) => {
  const {
    handlegetBlockedClients,
    blockedClients,
    handleManageBlockedClients,
    setToast,
  } = OrdersRequestController(props);
  const classes = useStyles();
  const [clients, setClients] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [observation, setObservation] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    handlegetBlockedClients();
  }, []);

  const handleLockClick = (client) => {
    setSelectedClient(client);
    setOpen(true);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setObservation("");
    setSelectedClient(null);
  };

  const handleSave = async () => {
    if (selectedClient) {
      setClients((prevClients) =>
        prevClients.map(
          (client) =>
            client.applicant_name === selectedClient.applicant_name
              ? {
                  ...client,
                  status: client.locked === 0 ? "Activo" : "Bloqueado",
                }
              : client
        )
      );
      await handleManageBlockedClients(selectedClient, observation);
    }
    handleClose();
  };

  const filteredRequests = blockedClients.filter(
    (request) =>
      request.applicant_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(request.id)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  return (
    <div className={classes.containerView}>
      <Typography variant="h5">
        Gestión de clientes bloqueados por cartera
      </Typography>
      <TableContainer component={Paper}>
        <Box className={classes.searchBox}>
          <TextField
            className={classes.searchField}
            label="Buscar cliente"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              endAdornment: <Search />,
            }}
          />
        </Box>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre cliente</TableCell>
              <TableCell>Shipto</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Detalle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRequests?.map((client, index) => (
              <TableRow key={index}>
                <TableCell>{client?.applicant_name}</TableCell>
                <TableCell>{client?.id}</TableCell>
                <TableCell>
                  {client?.locked === 1 ? "Bloqueado" : "Activo"}
                </TableCell>
                <TableCell>
                  <IconButton
                    className={classes.lockIcon}
                    onClick={() => handleLockClick(client)}
                  >
                    {client?.locked === 1 ? (
                      <Lock color="secondary" />
                    ) : (
                      <LockOpen color="primary" />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Desbloqueo de cliente - {selectedClient?.name}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="observation"
            label="Observación área de cartera"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Volver
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(ManagementBlockedClients)
);
